import * as Rollbar from 'rollbar';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
// import { RollbarService } from 'app/app.module';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  errorMessage: any;
  constructor(private router: Router, private injector: Injector) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        // const rollbar = this.injector.get(RollbarService);
        if (error.error instanceof ErrorEvent) {
          // client-side error
          this.errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          this.errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        // window.alert(this.errorMessage);
        // rollbar.error(new Error(error.message).stack);
        if ([401, 403].includes(error.status)) {
          console.error(error);
          return throwError(error);
        } else if (error.status === 500) {
          console.error(error);
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );
  }
}
