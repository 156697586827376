// 1. Import the libs you need

import * as Rollbar from 'rollbar'; // When using Typescript < 3.6.0.
import { BrowserModule } from '@angular/platform-browser';
import {
  Injectable,
  Injector,
  InjectionToken,
  NgModule,
  ErrorHandler,
  Inject,
} from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JwtInterceptor, ServerErrorInterceptor } from './shared/interceptors';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import {
  registerLocaleData,
  CommonModule,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import en from '@angular/common/locales/en';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { IconDefinition } from '@ant-design/icons-angular';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { DataTablesModule } from 'angular-datatables';
import { environment } from 'environments/environment';

registerLocaleData(en);

const rollbarConfig = {
  accessToken: '8b9b41a3210b4f2c8b97f526081aeb46',
  captureUncaught: true,
  captureUnhandledRejections: true,
};

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(
  (key) => antDesignIcons[key]
);

// @Injectable()
// export class RollbarErrorHandler implements ErrorHandler {
//   constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

//   handleError(err: any): void {
//     this.rollbar.error(err.originalError || err);
//   }
// }

// export function rollbarFactory() {
//   return new Rollbar(rollbarConfig);
// }

// export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzIconModule.forRoot(icons),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    // }),
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    // { provide: ErrorHandler, useClass: RollbarErrorHandler },
    // { provide: RollbarService, useFactory: rollbarFactory },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ServerErrorInterceptor,
    //   multi: true,
    // },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: NZ_I18N, useValue: en_US },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
